import React, { useEffect } from "react"
import styled from "styled-components"
import HeroBkg from "../microsites/investor-relations/images/dfy-RlIgBOphKxU-unsplash.jpg"
import Google from "../images/badges/google.svg"
import Bkg from "../images/device-bky.png"
import Apple from "../images/badges/apple.svg"
import AppSectionContainer from "../microsites/new-app-download/app-section-container"
import "bootstrap/dist/css/bootstrap.min.css"
import Meta from "../utilities/seo"
import AppHeader from "../components/layout/app-header"

const Splash = styled.div`
  height: 38vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--c-blue-200);
  overflow: hidden;
  position: relative;

  h1 {
    font-family: var(--xn-font-body);
    font-weight: bold;
    color: #fff;
    font-size: 1.5rem;
    letter-spacing: -3px;
    position: relative;
    z-index: 1;
    padding-top: 5vh;
  }

  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
  }
`

const Hero = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 90%;

  img {
    width: 100%;
    height: 100%;
    transform: scale3d(-1, 1, 1);
    object-fit: cover;
    opacity: 40%;
    ${"" /* filter: grayscale(100%); */}
  }
`

const Box = styled.main`
  background-color: #000;
  min-height: 100vh;
  padding-bottom: 2rem;

  .collection {
    //padding: 1.5rem;
    border-radius: 0.75rem;
    margin-bottom: -30rem;
    //background: #75cbba17;
  }

  .text-mint {
    color: #00dbbc;
  }

  .font-weight500 {
    font-weight: 500;
  }
  .android-store {
    width: 200px;
  }

  .apple-store {
    width: 196px;
  }

  .margintopxx {
    margin-top: 390px;
  }

  .x-link-color {
    color: rgb(255, 255, 255) !important;
  }
`

const Ele = () => {
  return (
    <>
      <Meta
        title="Download XENO App"
        description="Goal Based - Investment"
        image={{
          url: "https://res.cloudinary.com/xeno-technologies/image/upload/v1716884754/bkg_fsfdt6.jpg",
          height: "1920",
          width: "1080",
        }}
      />
      <AppHeader />
      <Box>
        <Splash>
          <Hero>
            <img src={HeroBkg} alt="" />
          </Hero>
        </Splash>
        <AppSectionContainer>
          <section className="collection">
            <div className="container ixs-mobile-container-margin">


              <div className="row">
                <div className="col-sm-4 col-md-4 col-xl-4">
                  <div className="margintopxx">
                    <div className="pb-sm-3 pb-lg-3 pb-xl-3">
                      <h1 className="font-weight500 text-center text-white xh1">
                        Download <span className="text-mint">Now!</span>
                      </h1>
                    </div>
                    <div className="d-flex justify-content-center">
                      <div className="p-2">
                        <a
                          href="https://play.google.com/store/apps/details?id=com.xeno.investment&showAllReviews=true"
                          rel="noreferrer"
                          target="_blank"
                          className="google"
                          id="app_download_link__google_play"
                        >
                          <img src={Google} alt="" className="android-store" />
                        </a>
                      </div>
                      <div className="p-2">
                        <a
                          rel="noreferrer"
                          target="_blank"
                          className="apple"
                          href="https://apps.apple.com/ug/app/xeno-investment/id1572436933#?platform=iphone"
                          id="app_download_link__apple_store"
                        >
                          <img src={Apple} alt="" className="apple-store" />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-sm-8 col-md-8 col-xl-8 xs-mobile">
                  <img src={Bkg} alt="" className="x-img" />
                </div>
              </div>
            </div>
          </section>
        </AppSectionContainer>
      </Box>
    </>
  )
}

export default Ele
